<template>
<div>
	<div class="row">
		<div class="col-12 title-bar">
				<h1 class="pb-0" v-if="currentAction == 'create'">{{ $t('users.createUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == 'update'">{{ $t('users.editUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == ''">{{ $t('menu.allUsers') }}</h1>
				<ul class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
					<ol class="breadcrumb pb-0">
						<li class="breadcrumb-item">
							<a href="/page/app" class="active" target="_self">{{ $t('menu.app') }}</a>
						</li>
						<li class="breadcrumb-item active">
							<span aria-current="location">{{ $t('menu.allUsers') }}</span>
						</li>
					</ol>
				</ul>
				<p>{{ $t('users.subTitle') }}</p>
				<div class="separator mb-5"></div>
			</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<SbaCrudTable v-if="userTable" :key="$route.path" :my-table="userTable" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
			userTable: null,
			currentAction: '',
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},
	methods: {
		tableReady: function (table) {
			if (this.$route.params !== undefined && this.$route.params.action !== undefined) {
				switch(this.$route.params.action) {
					case 'add':
						//add new user
						this.userTable.toggleButton(this.userTable.buttons['C'], {}, false);
						this.currentAction = 'create';
						break;
					case 'update':
						if (this.$route.params.id !== undefined && this.userTable.data != undefined && this.userTable.data.rows != undefined) {
							//get location on name
							var currentRow = this.userTable.data.rows.filter(function(row) { 
								if (row['id'] == this.$route.params.id) {
									return row;
								}
							}, this);
							
							if (currentRow[0] !== undefined) {
								this.userTable.toggleButton(this.userTable.buttons['U'], currentRow[0], false);
								this.currentAction = 'update';
							}
						}
						break;
					case 'profile':
						//update user
						this.userTable.toggleButton(this.userTable.buttons['U'], this.currentUser, false);
						this.currentAction = 'update';
						break;
				}
			}
		},
		afterChange: function (table, data, crudFunction) {
			// Called after SBA Crud Table has performed a change
		},
		sbaCrudTableChange: function(tableName, fieldName, fieldValue, eventType) {
			// Called when SBA Crud Table field change
		}
	},
	mounted () {
		let uploadFileUrl = '/user/uploadImage';
		let deleteFileUrl = '/user/deleteImage';
		let getFileUrl = '/user/getImage';
	
		// set user table
		this.userTable = {
			name: this.$t('users.title'),
			controller: 'user',
			baseURL: process.env.VUE_APP_API_URL,
			function: 'getAll',
			pageLimit: 25,
			tableFold: false,
			showRowCount: false,
			showFilters: false,
			onChangeFieldFunction: this.sbaCrudTableChange,
			autoChangeTo: ['organization_id', 'user_role_id'],
			getCSV: true,
			putCSV: true,
			fields: [
				'organization_id',
				'user_role_id',
				'name',
				'function',
				'email',
				'phone_internal',
				'phone_mobile',
				'password',
				'profile_image',
			],
			labels: {
				selectLabel: this.$t('general.select-option'),
				warningRequiredStartLabel: this.$t('general.warning-required-start'),
				warningRequiredEndLabel: this.$t('general.warning-required-end'),
				rows: this.$t('general.rows'),
				lastUpdated: this.$t('general.last-updated'),
				selected: this.$t('general.selected'),
				message: this.$t('general.message'),
				insertSending: this.$t('general.insert-sending'),
				insertOk: this.$t('general.insert-ok'),
				insertNotOk: this.$t('general.insert-not-ok'),
				insertError: this.$t('general.insert-error'),
				updateSending: this.$t('general.update-sending'),
				updateOk: this.$t('general.update-ok'),
				updateNotOk: this.$t('general.update-not-ok'),
				updateError: this.$t('general.update-error'),
				deleteSending: this.$t('general.delete-sending'),
				deleteOk: this.$t('general.delete-ok'),
				deleteNotOk: this.$t('general.delete-not-ok'),
				deleteError: this.$t('general.delete-error'),
			},
			fieldDetails: {
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'organization_id': {
					label: this.$t('users.table.organization'),
				},
				'user_role_id': {
					label: this.$t('users.table.user_role'),
				},
				'name': {
					label: this.$t('users.table.name'),
				},
				'function': {
					label: this.$t('users.table.function'),
				},
				'phone_internal': {
					label: this.$t('users.table.phone_internal'),
				},
				'phone_mobile': {
					label: this.$t('users.table.phone_mobile'),
				},
				'email': {
					label: this.$t('users.table.email'),
					placeholder: this.$t('users.placeholders.email'),
					goToLink: 'mailto:',
				},
				'password': {
					label: this.$t('users.table.password'),
					hideList: true,
					type: 'password',
				},
				'profile_image': {
					label: this.$t('users.table.profile_image'),
					hideList: true,
					type: 'file',
					uploadFileUrl: uploadFileUrl,
					deleteFileUrl: deleteFileUrl,
					getFileUrl: getFileUrl,
					paramName: 'image',
					dropzoneDescription: this.$t('sections.dropHereToUpload'),
					multiple: false,
				},
			},
			buttons: {
				'C':{
					'id': 'c',
					'name': this.$t("users.buttons.insert"),
					'confirmName': this.$t("users.buttons.insert"),
					'icon': 'plus-square',
					'confirmClass': 'btn-square bg-success',
					'header': this.currentUser.rights['user']['insert'],
					'customClass': 'crud-table-button-header-success',
				},
				'U':{
					'id': 'u',
					'confirmName': this.$t("users.buttons.update"),
					'confirmClass': 'btn-square bg-success',
					'inline':  this.currentUser.rights['user']['update'],
				},
			},
		};
	}
}
</script>
