<template>
<div>
	<div class="row">
		<div class="col-12 title-bar">
				<h1 class="pb-0" v-if="currentAction == 'create'">{{ $t('connections.createUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == 'update'">{{ $t('connections.editUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == ''">{{ $t('menu.allConnections') }}</h1>
				<ul class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
					<ol class="breadcrumb pb-0">
						<li class="breadcrumb-item">
							<a href="/page/app" class="active" target="_self">{{ $t('menu.app') }}</a>
						</li>
						<li class="breadcrumb-item active">
							<span aria-current="location">{{ $t('menu.allConnections') }}</span>
						</li>
					</ol>
				</ul>
				<p>{{ $t('connections.subTitle') }}</p>
				<div class="separator mb-5"></div>
			</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<SbaCrudTable v-if="connectionTable" :key="$route.path" :my-table="connectionTable" />
		</div>
		<div class="col-md-12">
			<SbaCrudTable v-if="webHooks" :key="$route.path" :my-table="webHooks" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
			connectionTable: null,
			webHooks: null,
			currentAction: '',
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},

	mounted () {
		let uploadFileUrl = '/user/uploadImage';
		let deleteFileUrl = '/user/deleteImage';
		let getFileUrl = '/user/getImage';
	
		// set user table
		this.connectionTable = {
			name: this.$t('connections.title'),
			controller: 'Pbx',
			baseURL: process.env.VUE_APP_API_URL,
			function: 'getAll',
			pageLimit: 25,
			tableFold: false,
			showRowCount: false,
			showFilters: false,
			fields: [ 
				'organization_id',
				'type',
				'name',
				'username',
				'password',
				'hostname',
				'port',
				'version',
				'tenant_id',
				'client_id',
				'client_secret',
				'bot_id',
				'log_internal_calls',
				'debug',
				'cron_running',
				'cron_emergency_stop',
				'cron_heartbeat',
			],
			labels: {
				selectLabel: this.$t('general.select-option'),
				warningRequiredStartLabel: this.$t('general.warning-required-start'),
				warningRequiredEndLabel: this.$t('general.warning-required-end'),
				rows: this.$t('general.rows'),
				lastUpdated: this.$t('general.last-updated'),
				selected: this.$t('general.selected'),
				message: this.$t('general.message'),
				insertSending: this.$t('general.insert-sending'),
				insertOk: this.$t('general.insert-ok'),
				insertNotOk: this.$t('general.insert-not-ok'),
				insertError: this.$t('general.insert-error'),
				updateSending: this.$t('general.update-sending'),
				updateOk: this.$t('general.update-ok'),
				updateNotOk: this.$t('general.update-not-ok'),
				updateError: this.$t('general.update-error'),
				deleteSending: this.$t('general.delete-sending'),
				deleteOk: this.$t('general.delete-ok'),
				deleteNotOk: this.$t('general.delete-not-ok'),
				deleteError: this.$t('general.delete-error'),
			},
			autoChangeTo: ['organization_id'],
			fieldDetails: {
				'id': {
					hideEdit: true,
					hideList: true,
				},
				'organization_id': {
					label: this.$t('connections.table.organization'),
					type: 'combobox',
					'changeTo': ['organization.name'],
				},
				
				'type': {
					label: 'Type',
					type: 'enum',
					'options': [{
						'id': '3CX', 
						'name': '3CX'
					}, {
						'id': 'TEAMS', 
						'name': 'TEAMS',
					}],
				},	
				'name': {
					label: this.$t('connections.table.name'),
				},
				'username': {
					label: this.$t('connections.table.username'),
					hideList: true,
				},
				'hostname': {
					label: this.$t('connections.table.hostname'),
					hideList: true,
				},
				'password': {
					label: this.$t('connections.table.password'),
					hideList: true,
					type: 'password',
				},
				'port': {
					label: this.$t('connections.table.port'),
					hideList: true,
				},
				'version': {
					label: this.$t('connections.table.version'),
					hideList: true,
				},
				'tenant_id': {
					label: this.$t('connections.table.tenant_id'),
					hideList: true,
				},
				'client_id': {
					label: this.$t('connections.table.client_id'),
					hideList: true,
				},
				'client_secret': {
					label: this.$t('connections.table.client_secret'),
					hideList: true,
				},
				'bot_id': {
					label: this.$t('connections.table.bot_id'),
					hideList: true,
				},
				'log_internal_calls': {
					label: this.$t('connections.table.log_internal_calls'),
					hideList: true,
				},
				'debug': {
					label: this.$t('connections.table.debug'),
					hideList: true,
				},
				'cron_running': {
					label: this.$t('connections.table.cron_running'),
					hideEdit: true,
				},
				'cron_emergency_stop': {
					label: this.$t('connections.table.cron_emergency_stop'),
					hideList: true,
				},
				'cron_heartbeat': {
					label: this.$t('connections.table.cron_heartbeat'),
					hideEdit: true,
				},
			},
			buttons: {
				'C':{
					'id': 'c',
					'name': this.$t("connections.buttons.insert"),
					'confirmName': this.$t("connections.buttons.insert"),
					'icon': 'plus-square',
					'confirmClass': 'btn-square bg-success',
					'header': true,
					'customClass': 'crud-table-button-header-success',
				},
				'U':{
					'id': 'u',
					'confirmName': this.$t("connections.buttons.update"),
					'confirmClass': 'bg-success',
					'inline':  true,
				},
				'D' : {
					'id': 'd',
					'confirmName': this.$t("connections.buttons.delete"),
					'confirmClass': 'btn-square bg-success',
					'inline':  true,
				},
			},
		};
		
		this.webHooks = {
			name: this.$t('webhooks.title'),
			controller: 'Pbx_webhook',
			baseURL: process.env.VUE_APP_API_URL,
			function: 'getAll',
			pageLimit: 25,
			tableFold: false,
			showRowCount: false,
			showFilters: false,
			fields: [ 
				'organization_id',
				'pbx_id',
				'name',
				'event',
				'url',
				'data_function',
			],
			labels: {
				selectLabel: this.$t('general.select-option'),
				warningRequiredStartLabel: this.$t('general.warning-required-start'),
				warningRequiredEndLabel: this.$t('general.warning-required-end'),
				rows: this.$t('general.rows'),
				lastUpdated: this.$t('general.last-updated'),
				selected: this.$t('general.selected'),
				message: this.$t('general.message'),
				insertSending: this.$t('general.insert-sending'),
				insertOk: this.$t('general.insert-ok'),
				insertNotOk: this.$t('general.insert-not-ok'),
				insertError: this.$t('general.insert-error'),
				updateSending: this.$t('general.update-sending'),
				updateOk: this.$t('general.update-ok'),
				updateNotOk: this.$t('general.update-not-ok'),
				updateError: this.$t('general.update-error'),
				deleteSending: this.$t('general.delete-sending'),
				deleteOk: this.$t('general.delete-ok'),
				deleteNotOk: this.$t('general.delete-not-ok'),
				deleteError: this.$t('general.delete-error'),
			},
			autoChangeTo: ['organization_id', 'pbx_id'],
			fieldDetails: {
				'organization_id': {
					label: this.$t('webhooks.table.organization'),
					type: 'combobox',
					'changeTo': ['organization.name'],
				},	
				'pbx_id': {
					label: this.$t('webhooks.table.pbx_id'),
					type: 'combobox',
					'changeTo': ['pbx.name'],
				},
				'name': {
					label: this.$t('webhooks.table.name'),
				},
				'event': {
					label: this.$t('webhooks.table.event'),
				},
				'url': {
					label: this.$t('webhooks.table.url'),
				},
				'data_function': {
					label: this.$t('webhooks.table.data_function'),
				},
			},
			buttons: {
				'C':{
					'id': 'c',
					'name': this.$t("connections.buttons.insert"),
					'confirmName': this.$t("connections.buttons.insert"),
					'icon': 'plus-square',
					'confirmClass': 'btn-square bg-success',
					'header': true,
					'customClass': 'crud-table-button-header-success',
				},
				'U':{
					'id': 'u',
					'confirmName': this.$t("connections.buttons.update"),
					'confirmClass': 'bg-success',
					'inline':  true,
				},
				'D' : {
					'id': 'd',
					'confirmName': this.$t("connections.buttons.delete"),
					'confirmClass': 'btn-square bg-success',
					'inline':  true,
				},
			},
		};
	}
}
</script>
