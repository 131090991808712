<template>
<div id="git">
	<div class="card">
		<div class="card-body">
			<h1 class="card-title">SBA GIT</h1>
			<h3 class="card-subtitle">Options</h3>
			
			<div class="row">
				<div class="col-md-6 col-lg">
					<button type="button" class="btn btn-danger btn-block m-1" @click="fetchLogs()">Fetch Logs</button>
				</div>
				<div class="col-md-6 col-lg">
					<button type="button" class="btn btn-danger btn-block m-1" @click="updatePhp()">Update PHP Files</button>
				</div>
				<div class="col-md-6 col-lg">
					<button type="button" class="btn btn-danger btn-block m-1" @click="getDiff()">Get Changes</button>
				</div>
				<div class="col-md-6 col-lg">
					<button type="button" class="btn btn-danger btn-block m-1" @click="getStatus()">Get Status</button>
				</div>
				<div class="col-md-6 col-lg">
					<button type="button" class="btn btn-danger btn-block m-1" @click="getSqlDiff()">Get SQL Changes <font-awesome-icon style="display: inline-block;" :icon="'spinner'" spin v-if="processingSqlDiff"></font-awesome-icon></button>
				</div>
			</div>
			
			<div class="row" v-if="changedFiles.length">
				<div class="col-md-12">
					<h4>Push to production</h4>
				</div>
				<div class="col-md-12">
					<ul>
						<li>
							<input type="checkbox" v-model="pushData.files['all']" /> 
							<label>All Files</label>
						</li>
						<li v-for="file in changedFiles" :key="file">
							<input v-bind:id="file" type="checkbox" v-model="pushData.files[file]" /> 
							<label v-bind:for="file">{{ file }}</label>	
						</li>
					</ul>
				</div>
				<div class="col-md-12">
					<input class="form-control" type="text" :readonly="loading" v-model="pushData.comment" placeholder="Commit Message" />
					<button type="button" :disabled="loading" class="btn btn-danger" @click="pushToDevelopment()">Push to development</button>
					<button type="button" :disabled="loading" class="btn btn-danger" @click="pushToProduction()">Push to production</button>
				</div>
			</div>
			<div class="row" v-if="logs">
				<div class="col-md-12">
					<h3>Logs</h3>
					<div v-html="logs"></div>
				</div>
			</div>
			<div class="row" v-if="Object.keys(changedSql).length">
				<div class="col-md-12">
					<!-- tables -->
					<div class="card mb-3" v-if="changedSql['tables'] && Object.values(changedSql['tables']).length">
						<div class="card-header">Tables</div>
						<div class="card-body">
							<h5 class="card-title">Differences in tables</h5>
							<p class="card-text">
								<ul>
									<li v-for="(table, index) in Object.values(changedSql['tables'])" :key="index">{{ table }}</li>
								</ul>
							</p>
						</div>
					</div>
					
					<!-- table structures -->
					<div class="card mb-3" v-if="changedSql['table_structures'] && Object.keys(changedSql['table_structures']).length">
						<div class="card-header">Table structures</div>
						<div class="card-body">
							<h5 class="card-title">Differences in table structures</h5>
							<p class="card-text">
								<template v-for="(structures, table) in changedSql['table_structures']" :key="table">
									<template v-if="Object.values(changedSql['table_structures'][table]).length">
										<table class="table table-bordered">
											<thead>
												<th colspan="2">{{ table }}</th>
											</thead>
											<tbody>
												<template v-for="(structureContent, field) in structures" :key="field">
													<tr>
														<td colspan="2">{{ field }}</td>
													</tr>
													<tr>
														<td>
															<b><i>Development</i></b>
															<div v-for="(key, index) in Object.keys(structureContent['development'])" :key="index">
																<b>{{ key }}:</b>&nbsp;{{ structureContent['development'][key] }}
															</div>
														</td>
														<td>
															<b><i>Production</i></b>
															<div v-for="(key, index) in Object.keys(structureContent['production'])" :key="index">
																<b>{{ key }}:</b>&nbsp;{{ structureContent['production'][key] }}
															</div>
														</td>
													</tr>
													<tr>
														<td colspan="2">
															<b><i>Query</i></b>
															<div>
																{{ structureContent['query'] }}
															</div>
														</td>
													</tr>
												</template>
											</tbody>
										</table>
										<hr />
									</template>
								</template>
							</p>
						</div>
					</div>
					
					<!-- table contents -->
					<div class="card mb-3" v-if="changedSql['table_contents'] && Object.keys(changedSql['table_contents']).length">
						<div class="card-header">Table contents</div>
						<div class="card-body">
							<h5 class="card-title">Differences in table contents</h5>
							<p class="card-text">
								<template v-for="(contents, table) in changedSql['table_contents']" :key="table">
									<table class="table table-bordered">
										<thead>
											<th colspan="2">{{ table }}</th>
										</thead>
										<tbody>
											<template v-for="(content, field) in contents" :key="field">
												<tr>
													<td colspan="2">{{ field }}</td>
												</tr>
												<tr>
													<td>
														<b><i>Development</i></b>
														<div v-for="(key, index) in Object.keys(content['development'])" :key="index">
															<b>{{ key }}:</b>&nbsp;{{ content['development'][key] }}
														</div>
													</td>
													<td>
														<b><i>Production</i></b>
														<div v-for="(key, index) in Object.keys(content['production'])" :key="index">
															<b>{{ key }}:</b>&nbsp;{{ content['production'][key] }}
														</div>
													</td>
												</tr>
												<tr>
													<td colspan="2">
														<b><i>Query</i></b>
														<div>
															{{ content['query'] }}
														</div>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
									<hr />
								</template>
							</p>
						</div>
					</div>
					
					<!-- query summary -->
					<div class="card mb-3" v-if="changedSql['query_summary']">
						<div class="card-header">
							<h5>Query summary</h5>
						</div>
						<div class="card-body">
							<div style="white-space: pre-wrap;" v-html="changedSql['query_summary']"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div> 
</template>
<script>
export default {
	name: 'git',
	data () {
		return {
			logs: "<html></html>",
			changedFiles: [],
			pushData: {
				files: {},
				comment: "",
			},
			apiUrl: "",
			loading: false,
			processingSqlDiff: false,
			changedSql: {},
		}
	},
	methods: {
		//get logs
		fetchLogs() {
			this.req('GET', process.env.VUE_APP_API_URL + "/git/getlogs").then((response) => {
				if (response.status) {
					this.logs = response.data.response
				}
			});
		},
		//update base
		updatePhp() {
			this.logs = "loading..."
			this.req('GET', process.env.VUE_APP_API_URL + "/git/updatephp").then((response) => {
				if (response.status) {
					this.logs = response.data.response;
				}
			});
		},
		//get different files
		getDiff() {
			this.req('GET', process.env.VUE_APP_API_URL + "/git/getdiff").then((response) => {
				if (response.status) {
					this.changedFiles = response.data.response.split('<br />').filter(function(item) { return item.trim(); });
					const arrFiles = [];
					this.changedFiles.forEach(function(item, index) {
						arrFiles.push(item.trim());
					});
					this.changedFiles = arrFiles;
				} else {
					this.changedFiles = [''];
				}
			});
		},
		//get status
		getStatus() {
			this.loading = true;
			
			this.logs = "";
			
			this.req('GET', process.env.VUE_APP_API_URL + "/git/getStatus").then((response) => {
				if (response.status) {
					let logs = response.data.response;
					this.logs = logs;
					if (logs.indexOf('branch is ahead of') !== -1) {
						this.changedFiles = [''];
					}
				} else if (!response.status) {
					this.logs = "Failed";
					this.changedFiles = [''];
				}
				this.loading = false;
			});
		},
		//push files to development
		pushToDevelopment() {
			this.loading = true;
			
			let selectedFiles = this.pushData.files;
			let data = {
				comment: this.pushData.comment,
			}
			
			if (Object.keys(selectedFiles).length) {
				data['files'] = Object.keys(selectedFiles).map(function(key) {
					if (selectedFiles[key]) {
						if (key === 'all') {
							return key;
						}
						return key.split("\t")[1];
					}
				});
				data['files'] = data['files'].filter(function(item) { return item; });
			}
			
			this.req('POST', process.env.VUE_APP_API_URL + "/git/pushtodevelopment", data).then((response) => {
				if (response.status) {
					this.logs = response.data.response;
					this.pushData = {
						files: {},
						comment: "",
					}
					this.changedFiles = [];
				}
				this.loading = false;
			}, (error) => {
				this.loading = false;
			});
		},
		//push files to production
		pushToProduction() {
			this.loading = true;
			
			let r = confirm("Are you sure the code can be pushed to production?");
			if (r == false) {
				this.loading = false;
				return false;
			}
			
			let selectedFiles = this.pushData.files;
			let data = {
				comment: this.pushData.comment,
			}
			
			if (Object.keys(selectedFiles).length) {
				data['files'] = Object.keys(selectedFiles).map(function(key) {
					if (selectedFiles[key]) {
						if (key === 'all') {
							return key;
						}
						return key.split("\t")[1];
					}
				});
				data['files'] = data['files'].filter(function(item) { return item; });
			}
			
			this.req('POST', process.env.VUE_APP_API_URL + "/git/pushtoproduction", data).then((response) => {
				if (response.status) {
					this.logs = response.data.response;
					this.pushData = {
						files: {},
						comment: "",
					}
					this.changedFiles = [];
				}
				this.loading = false;
			}, (error) => {
				this.loading = false;
			});
		},
		// ----- [SQL compare] ----- //
		getSqlDiff() {
			this.processingSqlDiff = true;
			this.req('GET', process.env.VUE_APP_API_URL + "/git/compareSql").then((response) => {
				if (response.status) {
					this.changedSql = response.data;
				}
				
				this.processingSqlDiff = false;
			}, (error) => {
				this.processingSqlDiff = true;
			});
		}
    }
}
</script>
