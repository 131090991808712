<template>
<div>
	<div class="row">
		<div class="col-12 title-bar">
				<h1 class="pb-0" v-if="currentAction == 'create'">{{ $t('organizations.createUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == 'update'">{{ $t('organizations.editUser') }}</h1>
				<h1 class="pb-0" v-if="currentAction == ''">{{ $t('menu.allOrganizations') }}</h1>
				<ul class="nav pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
					<ol class="breadcrumb pb-0">
						<li class="breadcrumb-item">
							<a href="/page/app" class="active" target="_self">{{ $t('menu.app') }}</a>
						</li>
						<li class="breadcrumb-item active">
							<span aria-current="location">{{ $t('menu.allOrganizations') }}</span>
						</li>
					</ol>
				</ul>
				<p>{{ $t('organizations.subTitle') }}</p>
				<div class="separator mb-5"></div>
			</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<SbaCrudTable v-if="connectionTable" :key="$route.path" :my-table="connectionTable" />
		</div>
	</div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data () {
		return {
			connectionTable: null,
			currentAction: '',
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'currentUser',
		})
	},

	mounted () {
		let uploadFileUrl = '/user/uploadImage';
		let deleteFileUrl = '/user/deleteImage';
		let getFileUrl = '/user/getImage';
	
		// set user table
		this.connectionTable = {
			name: this.$t('organizations.title'),
			controller: 'Organization',
			baseURL: process.env.VUE_APP_API_URL,
			function: 'getAll',
			pageLimit: 25,
			tableFold: false,
			showRowCount: false,
			showFilters: false,
			fields: [ 
				'name',
			],
			labels: {
				selectLabel: this.$t('general.select-option'),
				warningRequiredStartLabel: this.$t('general.warning-required-start'),
				warningRequiredEndLabel: this.$t('general.warning-required-end'),
				rows: this.$t('general.rows'),
				lastUpdated: this.$t('general.last-updated'),
				selected: this.$t('general.selected'),
				message: this.$t('general.message'),
				insertSending: this.$t('general.insert-sending'),
				insertOk: this.$t('general.insert-ok'),
				insertNotOk: this.$t('general.insert-not-ok'),
				insertError: this.$t('general.insert-error'),
				updateSending: this.$t('general.update-sending'),
				updateOk: this.$t('general.update-ok'),
				updateNotOk: this.$t('general.update-not-ok'),
				updateError: this.$t('general.update-error'),
				deleteSending: this.$t('general.delete-sending'),
				deleteOk: this.$t('general.delete-ok'),
				deleteNotOk: this.$t('general.delete-not-ok'),
				deleteError: this.$t('general.delete-error'),
			},
			fieldDetails: {
				'id': {
					hideList: true,
				},
				'name': {
					label: this.$t('organizations.table.name'),
				},
			},
			buttons: {
				'C':{
					'id': 'c',
					'name': this.$t("organizations.buttons.insert"),
					'confirmName': this.$t("organizations.buttons.insert"),
					'icon': 'plus-square',
					'confirmClass': 'btn-square bg-success',
					'header': true,
					'customClass': 'crud-table-button-header-success',
				},
				'U':{
					'id': 'u',
					'confirmName': this.$t("organizations.buttons.update"),
					'confirmClass': 'bg-success',
					'inline':  true,
				},
				'D' : {
					'id': 'd',
					'confirmName': this.$t("organizations.buttons.delete"),
					'confirmClass': 'btn-square bg-success',
					'inline':  true,
				},
			},
		};
	}
}
</script>
